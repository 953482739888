import React from "react";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Button,
    Dialog,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import {primary} from '../../theme';
import {DialogActions} from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Underline} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const EmailTemplateDialog = ({
                                        open,
                                        handleClose,
                                        disabled,
                                        newTemplateData,
                                        setNewTemplateData,
                                        dataChanged,
                                        setDataChanged,
                                        handleSave,
                                        data,
                                    }) => {

    const onValueChange = (key, event) => {
        setDataChanged(true);
        setNewTemplateData({...newTemplateData, [key]: event.target.value})
    }

    const onTemplateChange = (data) => {
        setDataChanged(true);
        setNewTemplateData({...newTemplateData, 'template': data})
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <AppBar sx={{position: 'relative', bgcolor: primary}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {'Wzór wiadomości' + (dataChanged ? " *" : "")}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField label="Nazwa" variant="outlined" fullWidth={true} disabled={disabled}
                                       defaultValue={data && data.name ? data.name : ''}
                                       onChange={(e) => {
                                           onValueChange('name', e)
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Temat" variant="outlined" fullWidth={true} disabled={disabled}
                                       defaultValue={data && data.subject ? data.subject : ''}
                                       onChange={(e) => {
                                           onValueChange('subject', e)
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={data && data.template ? data.template : ''}
                                onChange={ (eventInfo, batch) => {
                                    onTemplateChange(batch.getData())
                                }
                                }
                                config={{
                                    toolbar: {
                                        items: ['undo', 'redo', '|', 'bold', 'italic', 'underline' ,'|', 'paragraph'],
                                    },
                                    plugins: [
                                        Bold, Essentials, Italic, Mention, Paragraph, Undo, Underline
                                    ],
                                    initialData: '',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {disabled ?
                <></> :
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        <SaveIcon/> Zapisz zmiany
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};