import {useState, useEffect} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import {getDeliveredServiceOrders, getServiceDashboardData} from "../../services/serviceOrdersService";
import {getShipmentsDashboardData} from "../../services/shippingService";

function Home() {

    const [key, setKey] = useState(0);
    const [serviceData, setServiceData] = useState({});
    const [shippingData, setShippingData] = useState({});
    const [deliveredOrders, setDeliveredOrders] = useState([]);
    const getServiceData = async () => {
        getServiceDashboardData().then((response) => {
            setServiceData(response.data);
        })
    }

    const getShippingData = async () => {
        getShipmentsDashboardData().then((response) => {
            setShippingData(response.data);
        })
    }

    const getDelivered = async () => {
        getDeliveredServiceOrders().then((response) => {
           setDeliveredOrders(response.data) ;
        })
    }

    useEffect(() => {
        getDelivered().then(() => setKey(key+1));
        getServiceData().then(() => setKey(key + 1));
        getShippingData().then(() => setKey(key + 1));
    }, [])
    return (
        <div className='home' style={{padding: 20}}>
            <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}} rowSpacing={2} key={key}>
                <Grid item xs={3}>
                    <Paper elevation={3} style={{padding: 20, maxWidth: 400, height: 400, backgroundColor: "#06D6A0"}}>
                        <Typography variant="h4" sx={{mt: 2, mb: 2, textDecoration: 'underline'}}>Urządzenia
                            dostarczone</Typography>
                        <List sx={{
                            width: '100%',
                            maxWidth: 400,
                            bgcolor: '#06D6A0',
                            position: 'relative',
                            maxHeight: 300,
                            overflow: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#048B67',
                                outline: '1px solid slategrey'
                            },
                            '& ul': {padding: 0},
                        }}>
                            {deliveredOrders.map((value) => (
                                <ListItem
                                    key={value}
                                    disableGutters
                                >
                                    <ListItemText primary={
                                        <Typography variant="h5">{value.full_number ? value.full_number : "Brak"}</Typography>
                                    } secondary={value.device_name}/>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={3} style={{padding: 20, maxWidth: 400, height: 400, backgroundColor: "#118AB2"}}>
                        <Typography variant="h4" sx={{mt: 2, mb: 2, textDecoration: 'underline'}}>Zlecenia</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h4" sx={{mt: 2, mb: 2}}>Nowe</Typography>
                                    <Typography variant="h5" sx={{mt: 2}}>{serviceData ? serviceData.new : 0}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h4" sx={{mb: 2}}>Zaakceptowane</Typography>
                                    <Typography variant="h5" sx={{mt: 2}}>{serviceData ? serviceData.accepted : 0}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h4" sx={{mb: 2}}>Zakończone</Typography>
                                    <Typography variant="h5" sx={{mt: 2}}>{serviceData ? serviceData.realized : 0}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={3} style={{padding: 20, maxWidth: 400, height: 400, backgroundColor: "#7371FC"}}>
                        <Typography variant="h4" sx={{mt: 2, mb: 2, textDecoration: 'underline'}}>Wysyłki</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h4" sx={{mt: 2, mb: 2}}>Oczekujące</Typography>
                                    <Typography variant="h5" sx={{mt: 2}}>{shippingData ? shippingData.waiting : 0}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h4" sx={{mt: 2, mb: 2}}>Zrealizowane</Typography>
                                    <Typography variant="h5" sx={{mt: 2}}>{shippingData ? shippingData.sent : 0}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default Home;